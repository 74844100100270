import { createSlice } from "@reduxjs/toolkit";
import * as I from "./interfaces";
import * as A from "./actions";

const initialState: I.CurrencyState = {
  rates: { USD: 1, RUB: 90, THB: 36, CNY: 7.11, EUR: 0.9 },
};

const slice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setCurrency(state, action: I.ISetRatesAction) {
      state.rates = action.payload;
    },
    clear() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(A.updateRates.fulfilled, (state, action) => {
      state.rates = action.payload;
    });
  },
});

export const currencyReducer = slice.reducer;
export const currencyActions = { ...slice.actions, ...A };
