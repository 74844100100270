import { useMemo } from "react";
import { bindActionCreators } from "redux";
import { currencyActions } from ".";
import { useAppDispatch } from "../../app";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCurrencyRates } from "../../../api/currency";

export const useCurrencyActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(currencyActions, dispatch), [dispatch]);
};

export const updateRates = createAsyncThunk("updateRates", async () => {
  return fetchCurrencyRates()
    .then((r) => r.data)
    .catch((e) => {
      console.log(e);
      return { USD: 1, RUB: 90, THB: 36, "CNY":7.11,"EUR":0.9, };
    });
});
